import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
    to: any
    title?: string
    children: ReactNode
    className?: string
}
export default function LinkNoDecoration(props: Props) {
    const { onClick, to, title, children, className } = props;
    return <Link
        className={className}
        to={to}
        title={title}
        onClick={(e) => {
            const reg = new RegExp(`.*${to}$`);
            if (!!window.location.href.match(reg)) {
                window.location.reload();
            }
            onClick && onClick(e);
        }}
        style={{
            textDecoration: "none",
        }}>
        {children}
    </Link>;
}
