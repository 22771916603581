/**
 * Author: Raksa Eng
 */
import "./LanguageOptions.scss";

import iconEn from "../images/lang-svg/en.svg";
import iconKm from "../images/lang-svg/km.svg";

import ComponentMounted from "../elements/ComponentMounted";
import LocaleEventController from "./LocaleEventController";
import appI18n, {
    AppI18nContext,
    I18nType,
    LanguageLocale,
    LanguageType,
} from "./AppI18n";
import LinkNoDecoration from "../elements/LinkNoDecoration";

const localeEventController = LocaleEventController.getInstance();

export const langIcon = (locale?: LanguageLocale) => {
    if (locale) {
        return locale === LanguageLocale.ENGLISH ? iconEn : iconKm;
    }
    return localeEventController.getLocaleIndex() === LanguageType.KHMER ? iconKm : iconEn;
};

export const restoreState = async () => {
    try {
        await localeEventController.checkLocale();
    } catch (e) { }
};

type Props = {
};
type State = {
    localeIndex: LanguageType
}
export default class LanguageOptions extends ComponentMounted<Props, State> {
    static contextType = AppI18nContext
    context: I18nType = appI18n;
    state = {
        localeIndex: LanguageType.ENGLISH,
    }

    constructor(props: Props) {
        super(props);
        restoreState().then(() => {
            this.setStateWhenMounted({
                localeIndex: localeEventController.getLocaleIndex(),
            });
        });
    }

    onChange(index: LanguageType) {
        LocaleEventController.saveLocaleIndex(index);
        this.setStateWhenMounted({ localeIndex: index });
        appI18n.locale = LocaleEventController.toLocale(index, appI18n);
    }

    render() {
        const { localeIndex } = this.state;
        const appI18n = this.context;
        return (
            <div id="lang-option" className="p-8 ald-bg-secondary">
                <div className="body">
                    <div className="language">
                        <div className="label">
                            {appI18n.t("lang_choose_default_lang")}:</div>
                        <div>
                            {[
                                {
                                    localeIndex: LanguageType.ENGLISH,
                                    titleKey: "lang_english",
                                    icon: iconEn,
                                }, {
                                    localeIndex: LanguageType.KHMER,
                                    titleKey: "lang_khmer",
                                    icon: iconKm,
                                }, {
                                    localeIndex: LanguageType.AUTO,
                                    titleKey: "auto",
                                    icon: <i className="fas fa-globe" />,
                                },
                            ].map((lang, i) => {
                                const selected = localeIndex == lang.localeIndex;
                                return (
                                    <button key={i} disabled={selected}
                                        className={`ald-btn inline-block m-1 language-option ${selected ? "selected" : ""}`}
                                        onClick={() => this.onChange(lang.localeIndex)}>
                                        {appI18n.t(lang.titleKey)}
                                        {typeof lang.icon === "string" ?
                                            <img className="inline"
                                                width="50" src={lang.icon} alt={appI18n.t(lang.titleKey)} /> :
                                            lang.icon
                                        }
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <div className="m-10">
                        <LinkNoDecoration className="ald-btn ald-color-primary text-4xl"
                            to="/" title={appI18n.t("home")}>
                            <i className="fa fa-home ald-color-primary" />{appI18n.t("home")}
                        </LinkNoDecoration>
                    </div>
                </div>
            </div >
        );
    }
}
